<template>
  <div>
      
      <v-dialog v-model="show" max-width="800px">
      
        <v-container class="grey lighten-5">
          <v-row class="py-14" no-gutters>
            <!-- <p>{{user}}</p> -->
            <v-col
              cols="12"
              sm="6"
              class="text-center"
              v-for="price in prices"
              :key="price.id"
            >
              <v-card
                class="pa-2"
                outlined
                tile
                v-if="price && price.active"
              >
                  <v-card-text class="mt-2">
                    <h2 class="primary--text pb-2 text-capitalize">{{price.intervalTitle}}</h2>
                    <p class="body-1" v-if="price.interval == 'month'"><span class="font-weight-black">€{{(price.unit_amount / 100).toFixed(1)}}</span> / mese</p>
                    <!-- <p class="body-1" v-if="price.interval == 'year'">${{Math.round( price.unit_amount / 1200)}}</p> -->
                    <p class="body-1" v-if="price.interval == 'year'"> <span class="font-weight-black">€{{ (price.unit_amount / 1200).toFixed(1) }}</span> / mese <span class="caption">(pagamento annuale)</span></p>
                    <v-btn dark class="primary px-10" @click="createAndOpenCheckout(price)"> SCEGLI PIANO </v-btn>
                  </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <!-- Overlay -->
      <OverlayLoading
      :overlaymessage="overlaymessage"
      :overlaymessage2="overlaymessage2"
      :inProgress="inProgress"
       />
      </v-dialog>
    
  </div>
</template>

<script>
import { usersCollection } from "@/plugins/firebase.js";
import { mapGetters } from "vuex";
// eslint-disable-next-line no-undef
var stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);

export default {
  name: "SettingsTeamDialog",

  components: {
    OverlayLoading: () => import("@/components/OverlayLoading"),
  },

  data() {
    return {
      inProgress: false,
      overlaymessage: "Inizializzando una connessione sicura. Attendere prego.",
      overlaymessage2: "",
    };
  },

  props: {
    value: Boolean,
  },
  computed: {
    ...mapGetters({
      user: "user",
      prices: "prices",
    }),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
    
  methods: {
    async createAndOpenCheckout(selectedprice) {
      console.log(selectedprice)
      this.inProgress = true;

      // send to GA4 events for product click -> add to cart
      // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce#measure_product_clicks
      this.$gtag.query('event', 'add_to_cart', {
        currency: 'USD',
        items: [{
          item_id: selectedprice.id,
          item_category: selectedprice.interval,
          quantity: 1,
          price: selectedprice.unit_amount / 100,
          item_name: selectedprice.interval + 'ly ' + selectedprice.unit_amount / 100,
          index: 1,
        }]
      });

      // console.log('user -> ' + this.user.id)
      // console.log('selected price id  -> ' + selectedprice.id)

      const docRef = await usersCollection
        .doc(this.user.id)
        .collection("checkout_sessions")
        .add({
          price: selectedprice.id,
          allow_promotion_codes: true,
          success_url: window.location.origin,
          cancel_url: window.location.origin,
        });
      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot((snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
          // Show an error to your customer and 
          // inspect your Cloud Function logs in the Firebase console.
          alert(`An error occured: ${error.message}`);
        }
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          // Init Stripe
          // const stripe = Stripe('pk_test_1234');
          stripe.redirectToCheckout({ sessionId });
        }
      });
    },
  },
};
</script>

<style></style>